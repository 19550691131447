import Footer from '@zep/components/Footer';
import { BannerSpacer } from '@zep/components/StickyBanner';
import { Achievement, Ai, Cta, GoogleEducation, Introduction, Pricing, Review, Students, Teacher } from '@zep/module/landing/components';
export const Landing = () => {
  return <BannerSpacer headerHeight={{
    mobile: 65,
    normal: 65
  }} data-sentry-element="BannerSpacer" data-sentry-component="Landing" data-sentry-source-file="Landing.tsx">
      <div className="flex flex-col gap-md min-[751px]:gap-2xl min-[1025px]:gap-3xl">
        <Cta data-sentry-element="Cta" data-sentry-source-file="Landing.tsx" />
        <Introduction data-sentry-element="Introduction" data-sentry-source-file="Landing.tsx" />
        <Achievement data-sentry-element="Achievement" data-sentry-source-file="Landing.tsx" />
        <GoogleEducation data-sentry-element="GoogleEducation" data-sentry-source-file="Landing.tsx" />
        <Review data-sentry-element="Review" data-sentry-source-file="Landing.tsx" />
        <Teacher data-sentry-element="Teacher" data-sentry-source-file="Landing.tsx" />
        <Ai data-sentry-element="Ai" data-sentry-source-file="Landing.tsx" />
        <Students data-sentry-element="Students" data-sentry-source-file="Landing.tsx" />
        <div>
          <Pricing data-sentry-element="Pricing" data-sentry-source-file="Landing.tsx" />
          <Footer data-sentry-element="Footer" data-sentry-source-file="Landing.tsx" />
        </div>
      </div>
    </BannerSpacer>;
};