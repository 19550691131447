import { useState } from 'react';
import { SupportedLocaleMap } from '@zep/consts/localize';
import { useLocale } from '@zep/hooks';
import { FaceFillIcon, MapFillIcon, SparklingFillIcon } from '@zep/icons';
import { LandingTranslationKeys } from '@zep/types/translation';
import { useTranslation } from 'next-i18next';
import { SelectedVideo } from './SelectedVideo';
import { VideoInfoCard } from './VideoInfoCard';
interface Video {
  titleKey: LandingTranslationKeys;
  descriptionKey: LandingTranslationKeys;
  icon: JSX.Element;
  webm: SupportedLocaleMap<'ko' | 'en' | 'ja'>;
  mp4: SupportedLocaleMap<'ko' | 'en' | 'ja'>;
}
const VIDEO_DATA: Video[] = [{
  titleKey: 'landing.cta.video1Title',
  descriptionKey: 'landing.cta.video1Desc',
  icon: <FaceFillIcon className="size-[20px] min-[1025px]:size-[24px]" fill="inherit" />,
  webm: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ZbiVd3wK87duxL1HmPqD3Jy/HQaYh3wK87Ff336502a1254a9fc234bd51d16b2c266uxL1HpbQUIi9/1%EB%B2%88%20%EC%98%81%EC%83%81_%ED%80%B4%EC%A6%88%20%EC%A0%84%EB%B0%98%20%EC%98%81%EC%83%81%20%EB%AC%B8%EC%A0%9Cui%EC%97%85%EB%8D%B0%EC%9D%B4%ED%8A%B8(720p).webm',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/thXij3wK87cuxPUvazLnWBa/ol2qD3wK87h5951441ce282408a596afafd834c353euxPUvd7xtJxN/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%98%81%EC%96%B4_1%EB%B2%88%20%EC%98%81%EC%83%81(%ED%80%B4%EC%A6%88%ED%92%80%EC%9D%B4).webm',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/2SO493wK87duxPVtAmEdnsx/DPBxh3wK87hea25b13e6c88f97a65133acd0f72925buxPVtCQk7ExO/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%9D%BC%EB%B3%B8%EC%96%B4_1%EB%B2%88%20%EC%98%81%EC%83%81(%ED%80%B4%EC%A6%88%ED%92%80%EC%9D%B4).webm'
  },
  mp4: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/KcOaj3wK87cuxL1HmODyqRI/xPzjW3wK87F4591585454893b0a3bba837f85b871e9uxL1HpaygAHA/1%EB%B2%88%20%EC%98%81%EC%83%81_%ED%80%B4%EC%A6%88%20%EC%A0%84%EB%B0%98%20%EC%98%81%EC%83%81%20%EB%AC%B8%EC%A0%9Cui%EC%97%85%EB%8D%B0%EC%9D%B4%ED%8A%B8(720p).mp4',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/SkFWT3wK87duxPUztirm4OA/brZAy3wK87he737ce5e34efd71cb5279eb98ac07eb9uxPUzvjsCDUm/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%98%81%EC%96%B4_1%EB%B2%88%20%EC%98%81%EC%83%81(%ED%80%B4%EC%A6%88%ED%92%80%EC%9D%B4).mp4',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/EjLJc3wK87cuxPVtAvTiIup/mNSse3wK87F5bbce2ea29600dcd83d3784f9bba0c6duxPVtGM12nIV/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%9D%BC%EB%B3%B8%EC%96%B4_1%EB%B2%88%20%EC%98%81%EC%83%81(%ED%80%B4%EC%A6%88%ED%92%80%EC%9D%B4).mp4'
  }
}, {
  titleKey: 'landing.cta.video2Title',
  descriptionKey: 'landing.cta.video2Desc',
  icon: <SparklingFillIcon className="size-[20px] min-[1025px]:size-[24px]" fill="inherit" />,
  webm: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/y5u7D3wK87cuxL135MlNugS/Sd6dP3wK87hd8a4cd1b986dcdc1fb62c79db92e45d4uxL13aA7k0uh/2%EB%B2%88%20%EC%98%81%EC%83%81_AI%20%ED%80%B4%EC%A6%88%20%EB%A7%8C%EB%93%A4%EA%B8%B0(720p).webm',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/LFyP13wK87duxPUd1rSDMpT/3wm223wK87h2f73ee524375264d8ff6f2abefd7672duxPUd5QvaqTr/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%98%81%EC%96%B4_2%EB%B2%88%20%EC%98%81%EC%83%81(AI%20%ED%80%B4%EC%A6%88%20%EB%A7%8C%EB%93%A4%EA%B8%B0).webm',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/n7hKP3wK87duxPVtAqHQgM7/FjW1L3wK87F9a4a7c72f940a4f82ef486c9c84817abuxPVtGNlLEq7/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%9D%BC%EB%B3%B8%EC%96%B4_2%EB%B2%88%20%EC%98%81%EC%83%81(AI%20%ED%80%B4%EC%A6%88%20%EB%A7%8C%EB%93%A4%EA%B8%B0).webm'
  },
  mp4: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ILMuG3wK87duxL26z774nRr/UrxVv3wK87Fcdf39982d208709889060aa0f51dd874uxL26D2eFCej/2%EB%B2%88%20%EC%98%81%EC%83%81_AI%20%ED%80%B4%EC%A6%88%20%EB%A7%8C%EB%93%A4%EA%B8%B0(720p).mp4',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ixMq63wK87cuxPUCPmjC0xq/E12uZ3wK87hd11eeee747486ef16ae422f415bf9552uxPUCRkgFOdS/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%98%81%EC%96%B4_2%EB%B2%88%20%EC%98%81%EC%83%81(AI%20%ED%80%B4%EC%A6%88%20%EB%A7%8C%EB%93%A4%EA%B8%B0).mp4',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/2Yd6U3wK87cuxPVtAzEBXTR/jQtKc3wK87hadfcfd9a537b1f1e0e67c9789f0859fauxPVtDLfa47z/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%9D%BC%EB%B3%B8%EC%96%B4_2%EB%B2%88%20%EC%98%81%EC%83%81(AI%20%ED%80%B4%EC%A6%88%20%EB%A7%8C%EB%93%A4%EA%B8%B0).mp4'
  }
}, {
  titleKey: 'landing.cta.video3Title',
  descriptionKey: 'landing.cta.video3Desc',
  icon: <MapFillIcon className="size-[20px] min-[1025px]:size-[24px]" fill="inherit" />,
  webm: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/fsfjh3wK87duxL135N5wJyu/XT4EP3wK87hd8a4cd1b986dcdc1fb62c79db92e45d4uxL13aA8y6EU/3%EB%B2%88%20%EC%98%81%EC%83%81_%EB%A7%B5%EA%B3%BC%20%EC%95%84%EB%B0%94%ED%83%80(720p).webm',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/Nrgpk3wK87cuxPUd1tAsxup/eLpoK3wK87h2f73ee524375264d8ff6f2abefd7672duxPUd5QY1m91/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%98%81%EC%96%B4_3%EB%B2%88%20%EC%98%81%EC%83%81(%EB%A7%B5%EA%B3%BC%20%EC%95%84%EB%B0%94%ED%83%80).webm',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/A3rLx3wK87cuxPVtAtU3oLA/BeGAC3wK87F9a4a7c72f940a4f82ef486c9c84817abuxPVtGN3YlBt/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%9D%BC%EB%B3%B8%EC%96%B4_3%EB%B2%88%20%EC%98%81%EC%83%81(%EB%A7%B5%EA%B3%BC%20%EC%95%84%EB%B0%94%ED%83%80).webm'
  },
  mp4: {
    ko: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/ly0Xu3wK87duxL2cFPZgLwK/cN4ol3wK87hd106b53e93fb7f8e3a8e11e8d9199889uxL2cI25sSAC/3%EB%B2%88%20%EC%98%81%EC%83%81_%EB%A7%B5%EA%B3%BC%20%EC%95%84%EB%B0%94%ED%83%80(720p).mp4',
    en: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/1g8tH3wK87duxPUFYrOpDfv/Fyr8Z3wK87F818ae5041b5bc5fb7f653149c899c4b2uxPUG1LQBcXN/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%98%81%EC%96%B4_3%EB%B2%88%20%EC%98%81%EC%83%81(%EB%A7%B5%EA%B3%BC%20%EC%95%84%EB%B0%94%ED%83%80).mp4',
    ja: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/23sov3wK87duxPVtAwsSUQN/XfMn23wK87F5bbce2ea29600dcd83d3784f9bba0c6duxPVtGMflPyh/%EC%A0%AD%ED%80%B4%EC%A6%88%20%EB%9E%9C%EB%94%A9_%EC%9D%BC%EB%B3%B8%EC%96%B4_3%EB%B2%88%20%EC%98%81%EC%83%81(%EB%A7%B5%EA%B3%BC%20%EC%95%84%EB%B0%94%ED%83%80).mp4'
  }
}];
export const Videos = () => {
  const {
    locale
  } = useLocale();
  const {
    t
  } = useTranslation(['landing']);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const selectedVideoInfo = VIDEO_DATA[selectedVideoIndex];
  const [progressRatio, setProgressRatio] = useState(0);
  const selectVideo = (videoIndex: number) => {
    if (videoIndex === selectedVideoIndex) return;
    setSelectedVideoIndex(videoIndex);
    setProgressRatio(0);
  };
  return <div className="flex w-full max-w-[1180px] flex-col gap-md pb-sm min-[751px]:gap-lg" data-sentry-component="Videos" data-sentry-source-file="Videos.tsx">
      <SelectedVideo key={locale + selectedVideoIndex} webmSrc={selectedVideoInfo.webm[locale] ?? selectedVideoInfo.webm.en} mp4Src={selectedVideoInfo.mp4[locale] ?? selectedVideoInfo.mp4.en} onProgress={setProgressRatio} onEnd={() => selectVideo((selectedVideoIndex + 1) % VIDEO_DATA.length)} data-sentry-element="SelectedVideo" data-sentry-source-file="Videos.tsx" />

      <ul className="flex flex-col gap-xs min-[750px]:flex-row min-[751px]:gap-[16px]">
        {VIDEO_DATA.map((video, index) => <VideoInfoCard key={index} title={t(video.titleKey)} description={t(video.descriptionKey)} icon={video.icon} progressRatio={progressRatio} isSelected={index === selectedVideoIndex} onClick={() => selectVideo(index)} />)}
      </ul>
    </div>;
};