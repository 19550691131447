import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'next-i18next';
export const Review = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  const REVIEWS = [{
    name: t('landing.review1.name'),
    from: t('landing.review1.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/lKUY83wK87duEDAq2XzFWk5/hZ28c3wK87h9d9fe90e13d7fba5bb3697ecf36f6deduEDAq5gJHZwS/profile1.webp',
    content: <Trans t={t} i18nKey="landing.review1.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review2.nameV2'),
    from: t('landing.review2.fromV2'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/I4bA93wK87cuEDAq2TZhQFZ/9VkD33wK87he3ec9ef8e203c9e46a61e8186130188fuEDAq5jBZ9lN/profile2.webp',
    content: <Trans t={t} i18nKey="landing.review2.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review3.name'),
    from: t('landing.review3.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/m1Xfc3wK87duEDAq2YhSY4y/DJhgb3wK87h980233b673aa1cf3e7ad8a3ced19dc7euEDAq5bor5WD/profile3.webp',
    content: <Trans t={t} i18nKey="landing.review3.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review4.name'),
    from: t('landing.review4.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/C1EgY3wK87duEDAq2HRLbP3/JFBs53wK87h1e062519b935bc34f775694b79f630cbuEDAq4EVozUE/profile4.webp',
    content: <Trans t={t} i18nKey="landing.review4.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review5.name'),
    from: t('landing.review5.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/TOgHS3wK87cuEDAvNMCWOTB/af8Ve3wK87F938ef65d1a956af622a7f606195ec4d3uEDAvSPxZ8eN/profile5.webp',
    content: <Trans t={t} i18nKey="landing.review5.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review6.name'),
    from: t('landing.review6.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/6UFDC3wK87duEDAvNKzPEXY/qM38h3wK87F938ef65d1a956af622a7f606195ec4d3uEDAvSPlLnkM/profile6.webp',
    content: <Trans t={t} i18nKey="landing.review6.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review7.name'),
    from: t('landing.review7.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/htyUS3wK87duEDAvNKo4sR1/uf8ly3wK87F938ef65d1a956af622a7f606195ec4d3uEDAvSPbYGyo/profile7.webp',
    content: <Trans t={t} i18nKey="landing.review7.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review8.name'),
    from: t('landing.review8.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/N2cMu3wK87cuEDAvNNuC0uy/K5WgI3wK87h92564b120863c1c9415c1620619f5706uEDAvQ0RXK45/profile8.webp',
    content: <Trans t={t} i18nKey="landing.review8.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review9.name'),
    from: t('landing.review9.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/diLbK3wK87cuEDAvNM5osE7/YGmL63wK87F7ae360e17d20e46ad984105acd0aa77cuEDAvSOGjeCN/profile9.webp',
    content: <Trans t={t} i18nKey="landing.review9.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review10.nameV2'),
    from: t('landing.review10.fromV2'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/4rnIZ3wK87cuEDAq2YdqQez/RVWgV3wK87h2199701834370532854b76bdb9854766uEDAq5eIXT3J/profile10.webp',
    content: <Trans t={t} i18nKey="landing.review10.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review11.name'),
    from: t('landing.review11.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/obfb83wK87duEDAq2YOV6Tg/hQjtQ3wK87hfb9ceb1861fd01f13041e7a8a452877cuEDAq5dylYXO/profile11.webp',
    content: <Trans t={t} i18nKey="landing.review11.contentV2" components={{
      Strong: <StrongText />
    }} />
  }, {
    name: t('landing.review12.name'),
    from: t('landing.review12.from'),
    avatarSrc: 'https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/nmOAm3wK87cuEDAq2VpYcFM/qtlga3wK87h351590ce3d7517c765280870b4267d3auEDAq5hcke7p/profile12.webp',
    content: <Trans t={t} i18nKey="landing.review12.contentV2" components={{
      Strong: <StrongText />
    }} />
  }];
  return <section className="flex flex-col items-center gap-2xl px-[16px] py-2xl min-[751px]:px-[24px] min-[1025px]:gap-3xl min-[1025px]:px-[40px] min-[1025px]:py-3xl" data-sentry-component="Review" data-sentry-source-file="Review.tsx">
      <div className="flex flex-col items-center gap-sm">
        <h2 className="text-center text-display-sm-extrabold text-default min-[751px]:whitespace-normal">
          {t('landing.teacher.title')}
        </h2>
        <p className="text-body-lg-semibold text-neutral">
          {t('landing.teacher.description')}
        </p>
      </div>

      <div className="relative h-[774px] w-full max-w-[1280px] overflow-y-hidden">
        <div className="columns-1 gap-[24px] min-[751px]:columns-3 min-[1025px]:columns-4">
          {REVIEWS.map(review => <ReviewCard key={review.name} {...review} />)}
        </div>
        <div className="absolute bottom-0 h-[100px] w-full bg-gradient-to-b from-[rgba(255,255,255,0)] to-white" />
      </div>
    </section>;
};
export const ReviewCard = ({
  name,
  from,
  avatarSrc,
  content
}: {
  name: string;
  from: string;
  avatarSrc: string;
  content: JSX.Element;
}) => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <figure className="mx-auto mb-md flex w-full max-w-[500px] break-inside-avoid flex-col gap-md rounded-md border border-alternative px-md py-lg shadow-sm min-[751px]:mb-[24px] min-[751px]:gap-lg min-[751px]:px-lg min-[751px]:py-xl" data-sentry-component="ReviewCard" data-sentry-source-file="Review.tsx">
      <figcaption className="flex items-center gap-xs" data-sentry-element="figcaption" data-sentry-source-file="Review.tsx">
        <img src={avatarSrc} alt={from} className="size-[36px]" loading="lazy" />
        <div className="flex flex-col gap-4xs">
          <span className="text-caption-md-medium text-neutral">{from}</span>
          <span className="text-body-sm-semibold text-neutral">
            {name} {t('landing.review.teacherTitle')}
          </span>
        </div>
      </figcaption>

      <blockquote className="text-body-lg-medium text-neutral">
        {content}
      </blockquote>
    </figure>;
};
const StrongText = ({
  children
}: PropsWithChildren) => <strong className="text-body-lg-bold text-default" data-sentry-component="StrongText" data-sentry-source-file="Review.tsx">{children}</strong>;