import { Trans, useTranslation } from 'next-i18next';
export const GoogleEducation = () => {
  const {
    t
  } = useTranslation(['landing', 'common']);
  return <section className="flex flex-col items-center gap-lg bg-neutral px-[16px] py-4xl min-[751px]:px-[24px] min-[1025px]:px-[40px]" data-sentry-component="GoogleEducation" data-sentry-source-file="GoogleEducation.tsx">
      <img className="w-[348px] min-[750px]:w-[560px]" src="/assets/google-zep-logo.webp" alt="Google for Education" loading="lazy" />

      <p className="text-center text-body-lg-medium text-neutral">
        <Trans t={t} i18nKey={'landing.googleLogo.description'} components={{
        Comp: <StrongText text={t('landing.googleLogo.accent')} />
      }} data-sentry-element="Trans" data-sentry-source-file="GoogleEducation.tsx" />
      </p>
    </section>;
};
const StrongText = (props: {
  text: string;
}) => <strong className="text-body-lg-bold text-default" data-sentry-component="StrongText" data-sentry-source-file="GoogleEducation.tsx">{props.text}</strong>;